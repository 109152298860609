<template>
  <LoadingView v-if="loading" />

  <div v-if="error" class="error">{{ error }}</div>

  <div class="filters">
    <div class="filters__item">
      <div class="checkbox">
        <div class="checkbox-square">
          <input id="special-dates" name="special-dates" type="checkbox" v-model="specialDates" />
          <span></span>
        </div>
        <label for="special-dates">Date speciali</label>
      </div>
    </div>
    <div class="filters__item">
      <div class="select-wrapper">
        <select class="form-control" v-model="currentYear">
          <option v-for="(item, index) in this.years" :value="item.year" :key="index">{{ item.year }}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="photos">
    <div
      v-for="(photo, innerIndex) in allPhotos"
      class="photo-item"
      :key="photo._id"
      :class="[`index--${innerIndex}`]"
    >
      <ImgCard :photo="photo" :src="imageUrlFor(photo.immagine).width(600)" :index="innerIndex" />
    </div>
  </div>
</template>

<script>
import LoadingView from "../components/LoadingView.vue";
import ImgCard from "../components/ImgCard.vue";
import sanity from "../client";
// import { useMainStore } from "../store.js";
import imageUrlBuilder from "@sanity/image-url";

const imageBuilder = imageUrlBuilder(sanity);

const query = `*[_type == "photo"] | order(data desc){
    _id,
    data,
    special,
    slug,
    immagine
  }`;

export default {
  name: "HomeView",
  components: {
    ImgCard,
    LoadingView
  },
  data() {
    return {
      loading: true,
      photos: [],
      years: [],
      currentYear: null,
      selectNum: 5,
      specialDates: false
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    allPhotos() {
      if (this.specialDates == true) {
        return this.filterByYear( this.filterBySpecial(this.photos) );
      }
      return this.filterByYear(this.photos);
    }
  },
  methods: {
    imageUrlFor(source) {
      return imageBuilder.image(source);
    },

    filterByYear: function(photos) {
      return photos.filter(photo => photo.year == this.currentYear);
    },

    filterBySpecial: function(photos) {
      return photos.filter(photo => photo.special == true);
    },

    fetchData() {
      this.error = this.photo = null;
      this.loading = true;
      sanity.fetch(query).then(
        photos => {
          this.loading = false;
          this.photos = photos;

          Object.entries(this.photos).forEach(([key, item]) => {
            const d = new Date(item.data);
            Object.assign(item, { year: d.getFullYear() });

            this.years[key] = { id: key, year: d.getFullYear() };

            this.years = [
              ...this.years
                .reduce((map, obj) => map.set(obj.year, obj), new Map())
                .values()
            ];

            if (!this.currentYear) {
              this.currentYear = this.years[0].year;
            }
          });
        },
        error => {
          this.error = error;
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
h2 {
  @include font-bold();
  font-size: 24px;
  line-height: 1;
  color: $secondary-color;
  display: flex;
  align-items: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
  &:after {
    content: "";
    width: 20%;
    height: 1px;
    background-color: $secondary-color;
    margin-left: 10px;
    opacity: 0.3;
  }
}

.photos {
  margin: 0 auto;
  max-width: $container_width;
  width: 100%;
  
  // background-color: $white;

  max-width: 600px;

  // display: grid;
  // grid-template-columns: repeat(6, 1fr);

  // @include col-xs() {
  //   grid-template-columns: repeat(2, 1fr);
  // }

  // @include col-sm() {
  //   grid-template-columns: repeat(3, 1fr);
  // }

  // @include col-md() {
  //   grid-template-columns: repeat(4, 1fr);
  // }

  // @include col-lg() {
  //   grid-template-columns: repeat(5, 1fr);
  // }
  .photo-item {
    // padding: $gap;
    box-sizing: border-box;

    // &.index--0 {
    //   grid-column: 1 / 3;
    //   grid-row: 1 / 3;
    // }
  }

  &.index--0 {
    .index--0 {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
    }
  }
}

.filters {
  max-width: $container_width;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 25px;
  .filters__item {
    &:first-child {
      margin-left: 0;
    }
    margin-left: 20px;

    .checkbox {
      position: relative;
      display: flex;
      align-items: center;

      label {
        position: relative;
        color: $gray;
        font-size: 16px;
        z-index: 355;
        cursor: pointer;
        // top: 0;
        // left: 25px;
        line-height: 1.2;
        display: block;
        padding-left: 35px;
        @include transition(0.2s);
        @include font-bold();
      }

      $checkbox_size: 20px;

      .checkbox-square {
        position: absolute;
        top: -2px;
        left: 0;
        width: $checkbox_size;
        height: $checkbox_size;
        z-index: 354;

        input[type="checkbox"] {
          opacity: 0;
          z-index: 2;
          float: left;
          width: $checkbox_size;
          height: $checkbox_size;
          margin: 0;
          padding: 0;
          // position: absolute;
          cursor: pointer;
        }
        input[type="checkbox"] + span {
          z-index: 1;
          position: relative;
          display: block;
          width: 100%;
          height: 100%;
          @include font-regular();
          @include transition(0.3s);
          cursor: pointer;
          white-space: nowrap;
          &:hover {
            border-color: transparent;
          }
          &:before {
            position: absolute;
            display: inline-block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 2px solid $gray;
            border-radius: 3px;
            background: transparent;
            content: "";
          }
          &:after {
            position: absolute;
            display: block;
            z-index: 2;
            content: "";
            top: -3px;
            right: -9px;
            width: 25px;
            height: 25px;

            opacity: 0.1;
            background-size: contain !important;
            background-position: 50% 50% !important;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27.56' height='21.502' viewBox='0 0 27.56 21.502'%3E%3Cpath fill='none' stroke='%23252525' stroke-width='3' d='M681.152,4966.322l7.118,7.118,18.321-18.321' transform='translate(-680.092 -4954.059)'/%3E%3C/svg%3E");
            @include transition(0.3s);
          }
        }
        input[type="checkbox"]:checked {
          border-color: transparent;
          color: #f00;
        }
        input[type="checkbox"]:checked + span {
          &:after {
            opacity: 1;
          }
        }
        input[type="checkbox"].error + span {
          &:before {
            border-color: #c00;
          }
        }
      }
    }

    @-moz-document url-prefix() {
      select,
      select:-moz-focusring,
      select::-moz-focus-inner {
        color: transparent !important;
        text-shadow: 0 0 0 #000 !important;
        background-image: none !important;
        border: 0;
      }
    }

    .select-wrapper {
      position: relative;

      select {
        position: relative;
        z-index: 1;
        background-color: transparent;
        background-image: none;
        border: 2px solid $gray;
        border-radius: 4px;
        box-shadow: none;
        color: $gray;
        text-indent: 5px;
        display: block;
        @include font-bold();
        font-size: 16px;
        padding: 0 30px 0 10px;
        text-indent: 0px;
        @include transition(0.3s);
        //vertical-align: middle;
        width: 100%;
        margin: 0;
        height: 30px;
        line-height: 26px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &.error {
          border-color: #f00;
          @include border-shadow(3px, #f00);
          @include col-xs() {
            @include border-shadow(2px, #f00);
          }
        }

        option {
          border: none;
          box-shadow: none;
          background-color: transparent;
          text-indent: 5px;
          padding: 10px 7px;
          font-size: 16px;
          color: $gray;
        }

        &:focus {
          border-color: none;
          box-shadow: none;
          -webkit-box-shadow: none;
          outline: -webkit-focus-ring-color auto 5px;
          outline: none !important;
          outline-color: transparent;
        }
      }

      &:after {
          z-index: 0;
          position: absolute;
          top: 0;
          height: 100%;
          width: 30px;
          right: 0;
          content: "";
          background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='123.959px' height='123.958px' viewBox='0 0 123.959 123.958' style='enable-background:new 0 0 123.959 123.958;' xml:space='preserve'%3E%3Cpath d='M117.979,28.017h-112c-5.3,0-8,6.4-4.2,10.2l56,56c2.3,2.3,6.1,2.3,8.401,0l56-56 C125.979,34.417,123.279,28.017,117.979,28.017z'/%3E%3C/svg%3E");
          background-size: 10px auto;
          background-repeat: no-repeat;
          background-position: 50% 50%;
        }
    }
  }
}
</style>