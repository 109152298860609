<template>
    <div class="loading-view">
        <div class="loading-view__icon">
            <svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 52 100" enable-background="new 0 0 0 0" xml:space="preserve">
            <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
                <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.1"/>    
            </circle>
            <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
                <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite" 
                begin="0.2"/>       
            </circle>
            <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
                <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite" 
                begin="0.3"/>     
            </circle>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoadingView",
}
</script>

<style lang="scss" scoped>
    .loading-view {
        background: $white;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 985;
        display: flex;
        justify-content: center;
        align-items: center;
        .loading-view__icon {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: 1px solid #DDD;
            display: flex;
            justify-content: center;
            align-items: center;
            
            svg {
                width: 80px;
                height: 80px;
                display: block;
                circle {
                    fill: #AAA;
                }
            }
        }
    }
</style>