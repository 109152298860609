<template>
    <article class="img-card">

        <div class="point" :class="{ point_special: photo.special }">
            <div class="point__ball"></div>
            <div class="dida" :class="{ dida_special: photo.special }">{{ dateTime(photo.data) }}</div>
        </div>

        <div class="card-inner">
            <div class="card-inner__content">
                <router-link :to="{ name: 'Photo', params: { slug: photo.slug.current } }">
                    <div class="special" v-if="photo.special">
                        <IconStar />
                    </div>
                    <figure class="img-card__wrapper">
                        <img class="lazy" loading="lazy" v-if="photo.immagine" :src="src" />
                    </figure>
                </router-link>
            </div>
        </div>
    </article>
</template>

<script>
import LazyLoad from "vanilla-lazyload";
import moment from 'moment';
import IconStar from "./IconStar.vue";

export default {
    name: "ImgCard",
    data() {
        return {
            imgSrc: null,
        };
    },
    props: {
        photo: {
            type: Object
        },
        src: {
            type: String
        },
        index: {
            type: Number
        }
    },
    mounted() {
        new LazyLoad({
            elements_selector: ".lazy",
            use_native: true,
            restore_on_error: true,
            callback_loaded: this.callBackImgLoaded
        });
    },
    methods: {
        dateTime(value) {
            return moment(value).format("DD-MM-YYYY");
        },
        callBackImgLoaded(trg) {
            trg.parentElement.parentElement.classList.contains("img-loaded") ? trg.parentElement.parentElement.classList.remove("img-loaded") : trg.parentElement.parentElement.classList.add("img-loaded");
        }
    },
    components: { IconStar }
}
</script>

<style lang="scss" scoped>

.img-card {

    display: flex;
    $small_size: 20px;
    $big_size: 40px;
    

    .point {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        margin-right: 20px;

        @include col-xs() {
            width: 30%;
        }
        
        
        &:before {
            position: absolute;
            z-index: 1;
            width: 1px;
            height: 100%;
            top: 0;
            left: 0;
            border-left: 2px dotted rgba(0,0,0,0.3);
            content: "";
        }

        .dida {
            position: relative;
            display: block;
            z-index: 3;
            background: $secondary-color;
            @include font-regular();
            font-size: 13px;
            line-height: 1;
            padding: 5px 9px;
            color: $white;
            white-space: nowrap;
            border-radius: 3px;
            @include transition(0.5s, all, cubic-bezier(0, 0.8, 0.2, 1), 0.5s);
            &.dida_special {
                @include font-bold();
                background: $primary-color;
            }

            @include col-xs() {
                font-size: 11px;
            }
        }
        .point__ball {
            position: relative;
            z-index: 2;
            width: $small_size;
            height: 3px;
            background-color: $secondary-color;
        }
        &.point_special {
            .point__ball {
            width: $big_size;
            height: 3px;
            background-color: $primary-color;
        }
        }
    }

    .card-inner {
        background: $white;
        width: 50%;
        padding: $gap;

        margin: 20px 0;
        
        .card-inner__content {
            position: relative;
            width: 100%;
            padding-top: 100%;
            a {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
                border-radius: 3px;

                .special {
                    position: absolute;
                    z-index: 2;
                    width: 40px;
                    height: 40px;
                    top: 5px;
                    right: 5px;
                    opacity: 0;
                    transform: scale(0.2);
                    @include transition(1s, all, cubic-bezier(0, 0.8, 0.2, 1), 1s);

                    svg {
                        width: 100%;
                        height: auto;
                        display: block;
                        transform: scale(1);
                        animation: pulse 2s infinite;
                    }
                }

                &.img-loaded {
                    .img-card__wrapper {
                        transform: scale(1);
                    }
                    .special {
                        transform: scale(1);
                        opacity: 1;
                    }
                }
                .img-card__wrapper {
                    padding: 0;
                    margin: 0;
                    position: relative;
                    transform: scale(2);
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    @include transition(0.5s);
                    
                    img {
                        position: relative;
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                        display: block;
                        object-fit: cover;
                        object-position: center;
                        @include transition(0.5s);
                        opacity: 0;
                        &.loaded {
                            opacity: 1;
                        }
                    }
                    
                }

                &:hover {
                    .img-card__wrapper {
                        img {
                            transform: scale(1.2);
                        }
                    }
                }
            
            }
        }
    }

    @keyframes pulse {
        0% {
            transform: scale(0.75);
        }

        70% {
            transform: scale(1);
        }

        100% {
            transform: scale(0.75);
        }
    }

}


</style>